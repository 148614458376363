@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-1rem);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.wrapper {
  display: flex;
  align-items: center;
  margin: 50px 0 40px;
  gap: 3rem;
}

.left {
  text-align: center;
  flex: 1;
  border: 1px solid #ffffff10;
  border-radius: 1rem;
  overflow: hidden;

  img {
    opacity: 0.9;
  }
}

.right {
  flex: 1;

  p {
    margin-bottom: 2rem;
  }
}

.sent {
  text-align: center;
  text-wrap: balance;
  animation: fadeIn 0.5s ease-in-out;
}

@media (max-width: 768px) {
  .wrapper {
    flex-direction: column;
  }

  .left {
    width: 100%;
  }

  .right {
    width: 100%;
  }
}
