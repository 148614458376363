.cookieWrapper {
  position: fixed;
  display: flex;
  gap: 4rem;
  align-items: center;
  justify-content: space-between;
  bottom: 0;
  left: 0;
  background: #000000dd;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  width: 100%;
  padding: 4rem;
  z-index: 1000000;
  border-top: 1px solid #ffffff20;

  p {
    opacity: 0.7;
    margin: 0;
  }

  .btns {
    display: flex;
    align-items: center;
    gap: 1rem;

    button {
      white-space: nowrap;
    }
  }
}

@media (max-width: 768px) {
  .cookieWrapper {
    flex-direction: column;
    padding: 2rem;
    gap: 2rem;
  }
}
