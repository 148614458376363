.iframeWrapper {
  display: none;

  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: #00000040;
  backdrop-filter: blur(30px);
  z-index: 1000;
  align-items: center;
  justify-content: center;

  &[data-visible="true"] {
    display: flex;
  }
}

.close {
  position: absolute;
  top: 2rem;
  right: 5rem;
  font-size: 4rem;
  cursor: pointer;
}

.iframe {
  border: none;
  border-radius: 0.5rem;
  height: 70vh;
  width: 1000px;
}

@media (max-width: 768px) {
  .iframe {
    width: 90vw;
    height: 85vh;
  }

  .close {
    top: 0.5rem;
    right: 2rem;
    font-size: 3rem;
  }
}
