.whitepaperform {
  div[data-required="true"] {
    position: relative;

    &:after {
      content: "*";
      color: #ffffff80;
      margin-left: 5px;
      position: absolute;
      top: 5px;
      right: 1rem;
    }
  }

  .requiredNotice {
    opacity: 0.5;
    margin: -1rem 0 1rem;
    display: block;
    text-align: right;
  }
}
